import { ThunkAction } from "redux-thunk";
import {
  AppStateType,
  ActionsType,
  LoginFormModel,
  RegisterFormModel,
  ChangePasswordFormModel,
  ProfileFormModel,
  ForgetPasswordFormModel,
  UserTokenFormModel,
  AdvertisementFormModel,
} from "../types";
import { axiosClient } from "../utils/axiosClient";
import apiUrls from "../utils/apiUrls";
import {
  addUserDataAction,
  appStatusAction,
  authStatusAction,
  logoutAction,
  getUserGroupsDataAction,
  GetAllBuData,
  getUserProfileAction,
  getCategoriesAction,
  getTokenDataAction,
  getUserTokenDataAction,
  getFAQDataAction,
  getWorkingDataAction,
  getTargetsAction,
} from "./Actions";
import { TFunction } from "i18next";

type Effect = ThunkAction<any, AppStateType, any, ActionsType>;

function checkAuthTimeOut(expTime: number): Effect {
  return function (dispatch) {
    setTimeout(() => {
      dispatch(logoutAction());
    }, expTime);
  };
}

export function checkAuthState(): Effect {
  return function (dispatch) {
    dispatch(
      appStatusAction({ error: false, loading: true, responseText: "" })
    );
    const accessToken = localStorage.getItem("token") || "";
    if (!accessToken) {
      dispatch(logoutAction());
    } else {
      const expiresIn = localStorage.getItem("expiresIn") || "0";
      const expirationDate = new Date(parseInt(expiresIn));
      if (expirationDate < new Date()) {
        dispatch(logoutAction());
      } else {
        const data = { jwtToken: accessToken };
        axiosClient()
          .post(apiUrls.checkUserStatus(), data)
          .then(async (res) => {
            dispatch(addUserDataAction(res.data.user));
            dispatch(authStatusAction(data.jwtToken));
            if (res.data.user.profile !== null) {
              const profile = await axiosClient().get(apiUrls.getUserProfile());
              dispatch(getUserProfileAction(profile.data.profile));
            }
            dispatch(
              appStatusAction({
                error: false,
                loading: false,
                responseText: "",
              })
            );
          })
          .catch((err) => {
            dispatch(
              appStatusAction({
                loading: false,
                error: false,
                responseText: "",
              })
            );
            dispatch(logoutAction());
          });
        dispatch<any>(
          checkAuthTimeOut(expirationDate.getTime() - new Date().getTime())
        );
      }
    }
  };
}

export function UserLogin(data: LoginFormModel, t: TFunction): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().post(apiUrls.login(), data);
      const expirationDate = new Date().getTime() + res.data.expiresIn;
      if (res.data.user.role !== "bu-user") {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: "Not Allowed",
          })
        );
        return;
      }
      localStorage.setItem("token", res.data.accessToken);
      localStorage.setItem("expiresIn", expirationDate.toString());
      dispatch(addUserDataAction(res.data.user));
      dispatch(authStatusAction(res.data.accessToken));
      console.log("imhere r erjehrjehr")
      if (res.data.user.profile !== null) {
        const profile = await axiosClient().get(apiUrls.getUserProfile());
        dispatch(getUserProfileAction(profile.data.profile));
      }
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function RegisterUser(data: RegisterFormModel, t: TFunction): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().post(apiUrls.register(), data);
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: t(res.data.message),
        })
      );
      // window.location.assign('/')
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function UserChangePassword(
  data: ChangePasswordFormModel,
  t: TFunction
): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().post(apiUrls.changePassword(), data);
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: t(res.data.message),
        })
      );
      // window.location.assign('/')
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function UserForgetPassword(
  data: ForgetPasswordFormModel,
  t: TFunction
): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().post(apiUrls.forgetPassword(), data);
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: t(res.data.message),
        })
      );
      // window.location.assign('/')
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function GetUserGroups(
  t: TFunction,
  active?: true | false,
  std?: true | false,
  id?: string,
  title?: string
): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().get(
        apiUrls.getUserGroup(active, std, id, title)
      );
      dispatch(getUserGroupsDataAction(res.data.result));
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
      // window.location.assign('/')
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}
export function GetAllProviderBu(t: TFunction): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().get(
        apiUrls.GetAllBU()
      );
      dispatch(GetAllBuData(res.data.data));
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
      // window.location.assign('/')
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function AddEditUserProfile(
  data: ProfileFormModel,
  providerUserId:string,
  picture: any,
  t: TFunction,
  type: "add" | "edit"
): Effect {
  data["providerUserId"] = providerUserId;
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const url =
        type === "edit" ? apiUrls.editUserProfile() : apiUrls.addUserProfile();
      const res = await axiosClient().post(url, data);

      if (picture !== "") {
        const pic = new FormData();
        pic.append("profile-pic", picture);
        await axiosClient().post(apiUrls.uploadProfilePic(), pic);
      }

      if (type === "add") {
        dispatch(addUserDataAction(res.data.user));
        const profile = await axiosClient().get(apiUrls.getUserProfile());
        dispatch(getUserProfileAction(profile.data.profile));
      } else {
        const profile = await axiosClient().get(apiUrls.getUserProfile());
        dispatch(getUserProfileAction(profile.data.profile));
      }

      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: t(res.data.message),
        })
      );
      // window.location.assign('/')
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function GetUserProfile(t: TFunction): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().get(apiUrls.getUserProfile());
      dispatch(getUserProfileAction(res.data.profile));
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function GetCategoiesWithSub(t: TFunction): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().get(apiUrls.getCategoiesWithSub());
      dispatch(getCategoriesAction(res.data.result));
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function GetTargetValues(t: TFunction, appName: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().get(apiUrls.getTargetValues(appName));
      if (res.data.result.length === 1) {
        dispatch(getTargetsAction(res.data.result[0].subCategory));
      } else {
        dispatch(getTargetsAction([]));
      }

      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function GetTokenClick(t: TFunction, ug: string[]): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );

      let ugIds = "";

      if (ug.length === 1) {
        ugIds = `ugIds=${ug[0]}`;
      }

      if (ug.length > 1) {
        ugIds = ug.reduce((acc, cur, ind) => {
          if (ind === 0) return (acc += `ugIds=${cur}`);
          return (acc += `&ugIds=${cur}`);
        }, "");
      }

      const res = await axiosClient().post(apiUrls.getTokenClick(ugIds));
      dispatch(getTokenDataAction(res.data.result));
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function AddUserToken(data: UserTokenFormModel, t: TFunction): Effect {
  var obj = {
    createdDate: data.createdDate,
    discount: data.discount,
    expiryDate: data.expiryDate,
    isCSV: data.isCSV,
    isGift: data.isGift,
    isPrivate: data.isPrivate,
    isPromo: data.isPromo,
    companyUserId: data.companyUserId,
    privateKeyValue: data.privateKeyValue,
    subCategoryCodeName: data.subCategoryCodeName,
    subCategoryId: data.subCategoryId,
    subCategoryName: data.subCategoryName,
    targetId: data.targetId,
    targetName: data.targetName,
    taxPercent: data.taxPercent,
    tokenId: data.tokenOnlyId,
    tokenName: data.tokenName,
    tokenNumber: data.tokenNumber,
    tokenPrice: data.tokenPrice,
    validDuration: data.validDuration,
  }
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().post(apiUrls.addUserToken(), obj);
       window.location.replace(res.data.paymentUrl);

      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function GetUserTokens(t: TFunction): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().get(apiUrls.getUserTokens());
      dispatch(getUserTokenDataAction(res.data.result));
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function AddEditAdvertisement(
  data: AdvertisementFormModel,
  picture: any,
  t: TFunction,
  type: "add" | "edit",
  ids: string
): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const url =
        type === "edit"
          ? apiUrls.editAdvertisement(ids)
          : apiUrls.addAdvertisement(ids);
      const res = await axiosClient().post(url, data);

      if (picture !== "") {
        const pic = new FormData();
        pic.append("upload-adv-img", picture);
        await axiosClient().post(
          apiUrls.uploadAdverImage(res.data.adverId),
          pic
        );
      }

      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: t(res.data.message),
        })
      );
      // window.location.assign('/')
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

export function GetFAQs(t: TFunction): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().get(apiUrls.getFaq());
      dispatch(getFAQDataAction(res.data.result));
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}
export function GetWorking(t: TFunction): Effect {
  return async function (dispatch) {
    try {
      dispatch(
        appStatusAction({
          loading: true,
          error: false,
          responseText: "",
        })
      );
      const res = await axiosClient().get(apiUrls.getWorking());
      dispatch(getWorkingDataAction(res.data.data));
      dispatch(
        appStatusAction({
          loading: false,
          error: false,
          responseText: "",
        })
      );
    } catch (err) {
      if (err.response) {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t(err.response.data.message),
          })
        );
      } else {
        dispatch(
          appStatusAction({
            loading: false,
            error: true,
            responseText: t("something-went-wrong"),
          })
        );
      }
      return;
    }
  };
}

