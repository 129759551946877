import React, { Suspense, useEffect } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { BeatLoader } from "react-spinners";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { AppStateType } from "./types";
import WrapperRender from "./hoc/WrapperRender";
import { useTranslation } from "react-i18next";
import { checkAuthState } from "./store/Effects";

const Layout = React.lazy(() => import("./components/Layout/Layout"));
const Product = React.lazy(() => import("./containers/Product/Product"));
const Faqs = React.lazy(() => import("./containers/Faqs/Faqs"));
const ExplainerVideos = React.lazy(() =>
  import("./containers/ExplainerVideos/ExplainerVideos")
);
const Home = React.lazy(() => import("./containers/Home/Home"));
const Login = React.lazy(() => import("./containers/Login/Login"));
const Logout = React.lazy(() => import("./containers/Logout/Logout"));
const Profile = React.lazy(() => import("./containers/Profile/Profile"));
const Register = React.lazy(() => import("./containers/Register/Register"));
const Settings = React.lazy(() => import("./containers/Settings/Settings"));
const ForgetPassword = React.lazy(() =>
  import("./containers/ForgetPassword/ForgetPassword")
);

function App() {
  const store = useSelector(
    (state: AppStateType) => state.mainStore,
    shallowEqual
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  let routes = (
    <Switch>
      <Route path={"/"} exact component={Home} />
      <Route path={"/product"} component={Product} />
      <Route path={"/faqs"} component={Faqs} />
      <Route path={"/explainer-video"} component={ExplainerVideos} />
      <Route path={"/profile"} component={Profile} />
      <Route path={"/settings"} component={Settings} />
      <Route path={"/logout"} component={Logout} />
      <Redirect to={"/"} />
    </Switch>
  );

  if (store.accessToken === "") {
    routes = (
      <Switch>
        <Route path={"/login"} component={Login} />
        <Route path={"/register"} component={Register} />
        <Route path={"/forget-password"} component={ForgetPassword} />
        <Redirect to={"/login"} />
      </Switch>
    );
  }

  return (
    <SimpleBar style={{ maxHeight: "100vh" }}>
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              width: "100%",
              margin: "0 auto",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <BeatLoader loading={true} color={"#707070"} size={15} margin={3} />
          </div>
        }
      >
        <WrapperRender
          render={() => <Layout>{routes}</Layout>}
          pageTitle={t(store.pageTitle)}
        />
      </Suspense>
    </SimpleBar>
  );
}

export default App;
