import * as yup from "yup";

export enum RoleEnum {
  ADMIN = "admin",
  BU = "bu-user",
  CU = "cu-user",
}

export enum TCUserEnum {
  BU = "bu-user",
  CU = "cu-user",
}

export type appInfo = { codeName: string; logoUrl: string; email: string };

export interface LoginFormModel {
  username: string;
  password: string;
}

export const validateLoginForm = yup.object().shape<LoginFormModel>({
  username: yup.string().required("valid.required"),
  password: yup.string().required("valid.required"),
});

export interface ForgetPasswordFormModel {
  username: string;
}

export const validateForgetPassword = yup
  .object()
  .shape<ForgetPasswordFormModel>({
    username: yup.string().required("valid.required"),
  });

export interface RegisterFormModel {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: RoleEnum;
}

export const validateRegisterForm = yup.object().shape<RegisterFormModel>({
  username: yup.string().required("valid.required"),
  confirmPassword: yup.string().required("valid.required"),
  email: yup.string().required("valid.required").email("valid.email"),
  password: yup.string().required("valid.required"),
  role: yup.mixed().required("valid.required"),
});

export interface ProfileFormModel {
  companyName: string;
  firstName: string;
  lastName: string;
  phoneNo: string;
  addition?: string;
  street: string;
  providerUserId:string;
  city: string;
  town: string;
  province: string;
  postalCode: string;
  houseNo: string;
  country: string;
  userGroup: string[];
}

export const validateProfileForm = yup.object().shape<ProfileFormModel>({
  country: yup.string().required("valid.required"),
  providerUserId: yup.mixed().notRequired(),
  houseNo: yup.string().required("valid.required"),
  postalCode: yup
    .string()
    .required("valid.required")
    .min(6, "valid.6-length-required")
    .max(6, "valid.6-length-required"),
  userGroup: yup.array(yup.string()).required("valid.required"),
  addition: yup.string().notRequired(),
  city: yup.string().required("valid.required"),
  firstName: yup.string().required("valid.required").min(3, "valid.too-short"),
  companyName: yup
    .string()
    .required("valid.required")
    .min(3, "valid.too-short"),
  lastName: yup.string().required("valid.required").min(3, "valid.too-short"),
  phoneNo: yup.string().required("valid.required").min(3, "valid.too-short"),
  province: yup.string().required("valid.required"),
  street: yup.string().required("valid.required"),
  town: yup.string().required("valid.required"),
});

export interface CommunFormModel {
  adminEmail: boolean;
  transEmail: boolean;
  promoEmail: boolean;
}

export const validateCommunForm = yup.object().shape<CommunFormModel>({
  adminEmail: yup.boolean().required("valid.required"),
  promoEmail: yup.boolean().required("valid.required"),
  transEmail: yup.boolean().required("valid.required"),
});

export interface ChangePasswordFormModel {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

export const validateChangePasswordForm = yup
  .object()
  .shape<ChangePasswordFormModel>({
    confirmPassword: yup
      .string()
      .required("valid.required")
      .min(9, "valid.min9Required"),
    currentPassword: yup.string().required("valid.required"),
    password: yup
      .string()
      .required("valid.required")
      .min(9, "valid.min9Required"),
  });

export interface PinCodeFormModel {
  newPinCode: string;
  confirmPinCode: string;
}

export const validatePinCodeForm = yup.object().shape<PinCodeFormModel>({
  confirmPinCode: yup
    .string()
    .required("valid.required")
    .min(4, "valid.min4Required"),
  newPinCode: yup
    .string()
    .required("valid.required")
    .min(4, "valid.min4Required"),
});

export interface UserTokenFormModel {
  subCategoryId: string;
  subCategoryName: string;
  subCategoryCodeName: string;
  companyUserId:string[];
  targetId?: string[];
  targetName?: string[];
  tokenOnlyId:string;
  tokenId: {_id:string,name:string};
  tokenPrice: string;
  isGift:boolean;
  isPromo:boolean;
  isCSV:boolean;
  taxPercent: string;
  discount: number;
  tokenName: string;
  tokenNumber: number;
  validDuration: number;
  expiryDate: string;
  createdDate: string;
  isPrivate: boolean;
  privateKeyValue: string;
}

export const validateTokenForm = yup.object().shape<UserTokenFormModel>({
  subCategoryId: yup.string().required("valid.required"),
  targetId: yup.mixed().notRequired(),
  targetName: yup.mixed().notRequired(),
  companyUserId: yup.mixed().notRequired(),
  tokenId: yup.mixed().required("valid.required"),
  tokenName: yup.mixed().required("valid.required"),
  tokenNumber: yup.mixed().required("valid.required"),
  tokenPrice: yup.mixed().required("valid.required"),
  discount: yup.mixed().required("valid.required"),
  taxPercent: yup.mixed().required("valid.required"),
  validDuration: yup.mixed().required("valid.required"),
  subCategoryName: yup.mixed().required("valid.required"),
  subCategoryCodeName: yup.mixed().required("valid.required"),
  tokenOnlyId: yup.mixed().required("valid.required"),
  isGift: yup.mixed().required("valid.required"),
  isPromo: yup.mixed().required("valid.required"),
  isCSV: yup.mixed().required("valid.required"),
  expiryDate: yup.mixed().required("valid.required"),
  createdDate: yup.mixed().required("valid.required"),
  isPrivate: yup.mixed().required("valid.required"),
  privateKeyValue: yup.mixed().required("valid.required"),
});

export interface AdvertisementFormModel {
  adName: string;
  adDescription: string;
  adStartDate: string;
  adEndDate: string;
  isActive: boolean;
  userToken: string;
  imgUrl?: string;
}

export const validateAdvertisementForm = yup
  .object()
  .shape<AdvertisementFormModel>({
    adName: yup.string().required("valid.required"),
    adDescription: yup.string().required("valid.required"),
    adStartDate: yup.string().required("valid.required"),
    adEndDate: yup.string().required("valid.required"),
    isActive: yup.boolean().required("valid.required"),
    userToken: yup.string().required("valid.required"),
    imgUrl: yup.string().notRequired(),
  });

export interface IAppStatus {
  loading: boolean;
  error: boolean;
  responseText: string;
}

export interface IUserData {
  _id: string;
  username: string;
  email: string;
  role: string;
  profile: string;
}

export interface IUserGroupData {
  _id: string;
  isActive: boolean;
  title: string;
  description: string;
}
export interface IBUDATA {
  _id: string;
  company: string;
  user_name: string;
}

export interface IUserProfileData extends ProfileFormModel {
  picture: string;
}

export interface ITokenData {
  _id: string;
  userGroup: string[];
  regions: string[];
  name: string;
  category:string,
  subCategoryName:string[],
  subCategory:string[],
  isGift:boolean,
  isPromo:boolean,
  isCSV:boolean,
  number: number;
  price: string;
  taxPercent: string;
  discount: number;
  userType: TCUserEnum;
  validDuration: number;
  isPrivate: boolean;
  privateKeyValue: string;
}

export interface ISubCategoryData {
  _id: string;
  calender: boolean;
  name: string;
  commission: number;
  codeName: string;
  category: string;
}

export interface ICategoriesData {
  _id: string;
  userGroup: string[];
  subCategory: ISubCategoryData[];
  name: string;
}

export interface IAdvertisementData extends AdvertisementFormModel {
  _id: string;
}

export interface IUserTokenData extends UserTokenFormModel {
  _id: string;
  genToken: string;
  advertisement: IAdvertisementData | null;
  paymentStatus: string;
  isCSV:boolean;
  productName:string;
  userName:string;
  subcategory:string;
  invoiceNo: string;
  orderNo: string;
  tokenUsed: number;
  cuTokens: { token: string; noUsed: number, expiryDate: string }[];
}

export interface IFAQData {
  _id: string;
  question: string;
  answer: string;
  userGroup: string;
  priority: number;
}
export interface IWorkingData {
  _id: string;
  Title: string;
  desc: string;
  userGroup: string;
  priority: number;
}

// Store
export type AppMainStore = {
  appStatus: IAppStatus;
  accessToken: string;
  user: IUserData;
  pageTitle: string;
  userGroups: IUserGroupData[];
  buData:IBUDATA[];
  userProfile: IUserProfileData;
  tokenData: ITokenData[];
  categories: ICategoriesData[];
  targets: ISubCategoryData[];
  userTokens: IUserTokenData[];
  faqs: IFAQData[];
  working: IWorkingData[];
};

// State
export type AppStateType = {
  mainStore: AppMainStore;
};

// Actions
type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? { type: Key }
    : { type: Key; payload: M[Key] };
};

export enum ActionsEnum {
  ACCESS_TOKEN = "ACCESS_TOKEN",
  APP_STATUS = "APP_STATUS",
  USER_DETAILS = "USER_DETAILS",
  LOGOUT = "LOGOUT",
  PAGE_TITLE = "PAGE_TITLE",
  GET_USER_GROUPS = "GET_USER_GROUPS",
  GET_BU_USERS="GET_BU_USERS",
  GET_USER_PROFILE = "GET_USER_PROFILE",
  GET_TOKEN_DATA = "GET_TOKEN_DATA",
  GET_CATEGORIES_DATA = "GET_CATEGORIES_DATA",
  GET_TARGETS_DATA = "GET_TARGETS_DATA",
  GET_USER_TOKENS = "GET_USER_TOKENS",
  GET_FAQ_DATA = "GET_FAQ_DATA",
  GET_WORKING_DATA = "GET_WORKING_DATA",
}

type Payloads = {
  [ActionsEnum.ACCESS_TOKEN]: string;
  [ActionsEnum.APP_STATUS]: IAppStatus;
  [ActionsEnum.USER_DETAILS]: IUserData;
  [ActionsEnum.LOGOUT]: undefined;
  [ActionsEnum.PAGE_TITLE]: string;
  [ActionsEnum.GET_USER_GROUPS]: IUserGroupData[];
  [ActionsEnum.GET_BU_USERS]: IBUDATA[];
  [ActionsEnum.GET_USER_PROFILE]: IUserProfileData;
  [ActionsEnum.GET_TOKEN_DATA]: ITokenData[];
  [ActionsEnum.GET_CATEGORIES_DATA]: ICategoriesData[];
  [ActionsEnum.GET_TARGETS_DATA]: ISubCategoryData[];
  [ActionsEnum.GET_USER_TOKENS]: IUserTokenData[];
  [ActionsEnum.GET_FAQ_DATA]: IFAQData[];
  [ActionsEnum.GET_WORKING_DATA]: IWorkingData[];
};

export type ActionsType = ActionMap<Payloads>[keyof ActionMap<Payloads>];
