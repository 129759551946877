import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import gbTrans from "./translations/gb.json";
import nlTrans from "./translations/nl.json";

const resources = {
  gb: { ...gbTrans },
  nl: { ...nlTrans },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "nl",
    fallbackLng: "nl",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((err) => err);

export default i18n;
