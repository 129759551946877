import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export const MainPageBtn = withStyles({
  root: {
    background: "#f2f2f2",
    borderRadius: 3,
    color: "white",
    height: 50,
    padding: "0 20px",
    marginRight: "10px",
    marginBottom: "10px",
    "&:hover": {
      background: "#ddd",
    },
  },
  label: {
    textTransform: "uppercase",
    fontSize: '1.5rem',
    color: '#1d1c1a'
  },
})(Button);

export const CusButtonPrimary = withStyles({
  root: {
    background: "#f68c1e",
    borderRadius: 3,
    color: "white",
    height: 40,
    padding: "0 20px",
    marginRight: "10px",
    marginBottom: "10px",
    "&:hover": {
      background: "#ffa31f",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

export const CusButtonSecondary = withStyles({
  root: {
    background: "#ddd",
    borderRadius: 3,
    color: "#000",
    height: 40,
    padding: "0 20px",
    marginRight: "10px",
    marginBottom: "10px",
    "&:hover": {
      background: "#ccc",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

export const CusListBtnRed = withStyles({
  root: {
    background: "#dc3545",
    borderRadius: 3,
    color: "white",
    height: 40,
    marginRight: "10px",
    fontSize: "0.8rem",
    lineHeight: "1rem",
    fontWeight: "normal",
    "&:hover": {
      background: "#dc3545",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

export const CusListBtnBlue = withStyles({
  root: {
    background: "#007bff",
    borderRadius: 3,
    color: "white",
    height: 40,
    marginRight: "10px",
    fontSize: "0.8rem",
    lineHeight: "1rem",
    fontWeight: "normal",
    "&:hover": {
      background: "#007bff",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

export const CusPaper = withStyles({
  root: {
    // backgroundColor: "#f7f7f7",
    // border: "3px #f68c1e solid",
    borderRadius: "15px",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
  },
})(Paper);

export const CusTextField = withStyles({
  root: {
    width: "100%",
  },
})(TextField);

export const CusPinTextField = withStyles({
  root: {
    width: "100%",
    "& > div": {
      fontSize: "2rem",
      letterSpacing: "1rem",
      "& > input": {
        textAlign: "center",
      },
    },
  },
})(TextField);

export const CusSelect = withStyles({
  // root: {
  //   border: "1px #f68c1e solid",
  // },
})(Select);

export const CusFormControl = withStyles({
  root: {
    width: "100%",
  },
})(FormControl);

export const CusFormHelperText = withStyles({})(FormHelperText);

export const CusInputLabel = withStyles({})(InputLabel);

export const CusInput = withStyles({})(Input);

export const CusMenu = withStyles({
  paper: {
    border: "1px #f68c1e solid",
  },
})(Menu);

export const CusMenuItem = withStyles({})(MenuItem);

export const CusDialog = withStyles({})(Dialog);

export const CusDialogActions = withStyles({})(DialogActions);

export const CusDialogContent = withStyles({})(DialogContent);

export const CusDialogContentText = withStyles({})(DialogContentText);

export const CusDialogTitle = withStyles({})(DialogTitle);

export const CusChip = withStyles({
  root: {
    background: "#707070",
    color: "white",
    height: 26,
    marginRight: "2px",
  },
})(Chip);

export const CusCheckbox = withStyles({})(Checkbox);

export const CusFormControlLabel = withStyles({})(FormControlLabel);

export const CusTableContainer = withStyles({})(TableContainer);

export const CusTable = withStyles({})(Table);

export const CusTableBody = withStyles({})(TableBody);

export const CusTableHead = withStyles({})(TableHead);

export const CusTableRow = withStyles({})(TableRow);

export const CusTableCell = withStyles({})(TableCell);

export const CusTableSortLabel = withStyles({})(TableSortLabel);
